'use client';

import { useParams, usePathname } from 'next/navigation';
import React from 'react';

import Main from './Main';

const MainLayout = (props: { children: React.ReactNode }) => {
  const pathname = usePathname();
  const { id } = useParams();
  const isOnlyHeader = [
    '/',
    `/ai-model/${id}/generate`,
    `/template/${id}`,
    `/showcase/${id}`,
  ].includes(pathname);
  const isEmptyLayout = [`/workflow/editor/${id}`].includes(pathname);

  return (
    <Main withEmptyLayout={isEmptyLayout} withOnlyHeader={isOnlyHeader}>
      {props.children}
    </Main>
  );
};

export default MainLayout;
