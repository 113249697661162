import { Navbar } from '@nextui-org/react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import { IconOnlyLogoAiRight, ToggleSidebar, ToggleSidebarOpen } from '@/assets';
import useWindowSize from '@/hooks/useWindowSize';
import { useLayout } from '@/provider/LayoutProvider';
import { useRouter } from '@/provider/RouterProvider';

import { useAuth } from '../../provider/UserProvider';
import HeaderMenu from './menu';

const DynamicUserAuth = dynamic(() => import('./UserAuth'), { ssr: false });
const DynamicHeaderNotification = dynamic(() => import('./notification'), { ssr: false });
const DynamicMenuMobile = dynamic(() => import('./menu/MenuMobile'));

const Header: React.FC<{ isHotStyle?: boolean }> = ({ isHotStyle }) => {
  const [scrolled, setScrolled] = useState(false);
  const { windowSize } = useWindowSize();
  const { setIsOpenSidebar, isOpenSidebar } = useLayout();
  const pathname = usePathname();
  const { isLogin } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (isHotStyle) {
      const handleScroll = () => {
        if (window.scrollY > 40) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isHotStyle]);

  return (
    <>
      <Navbar
        aria-checked={scrolled}
        aria-selected={isHotStyle && !scrolled}
        aria-expanded={pathname === '/' || isHotStyle}
        className="fixed flex !h-[60px] w-full select-none items-center justify-between border-b-1 border-gray-200 bg-white px-5 aria-checked:animate-header aria-expanded:fixed aria-expanded:z-[50] aria-selected:border-none aria-selected:bg-transparent aria-selected:backdrop-blur-[-1] xl:!h-[72px]"
        classNames={{ wrapper: 'p-0 h-full w-full max-w-full gap-0' }}
      >
        <div
          onClick={() => setIsOpenSidebar((prev) => !prev)}
          className="mr-4 flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-full bg-gray-50 xl:hidden"
        >
          {isOpenSidebar ? <ToggleSidebar /> : <ToggleSidebarOpen />}
        </div>
        <Link
          href="/"
          onClick={() => router.push('/')}
          className="flex items-center justify-between"
        >
          {isHotStyle && !scrolled ? (
            <div className="flex cursor-pointer items-center gap-2">
              <IconOnlyLogoAiRight />
              <span className="">aiRight</span>
              {/* <HomeLogoIconWhite className="" /> */}
            </div>
          ) : (
            // <HomeLogoIcon className="cursor-pointer" />
            <div className="flex cursor-pointer items-center gap-2">
              <IconOnlyLogoAiRight />
              <span className="">aiRight</span>
            </div>
          )}
        </Link>
        <div className="flex flex-1 items-center justify-between md:justify-end">
          {/* <div className="flex justify-between">
            {(windowSize.width > 1199 || windowSize.width === 0) && (
              <SearchHeader isHotStyle={isHotStyle} scrolled={scrolled} />
            )}
          </div> */}

          <div className="flex flex-1 items-center justify-end sm:flex sm:justify-end">
            <div className="flex items-center justify-center">
              {/* {(windowSize.width > 1199 || windowSize.width === 0) && <PriceAiri />} */}

              <HeaderMenu />
              {isLogin && <DynamicHeaderNotification isHotStyle={isHotStyle && !scrolled} />}
              <div className="w-[160px] text-end lg:!w-fit">
                <DynamicUserAuth isHotStyle={isHotStyle && !scrolled} />
              </div>
              {windowSize.width < 1200 && windowSize.width !== 0 && <DynamicMenuMobile />}
            </div>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
