import { CSSProperties } from "react";
import { match } from "ts-pattern";

const CLASS_POSITON = {
  'bottom': 'left-[50%] top-[100%] -translate-x-[50%]',
  'bottom-left': 'left-0 top-[100%]',
  'bottom-right': 'right-0 top-[100%]',
  'top': 'left-[50%] bottom-[100%] -translate-x-[50%]',
  'top-left': 'left-0 bottom-[100%]',
  'top-right': 'right-0 bottom-[100%]',
}

const CLASS_ORIGIN = {
  'bottom': 'origin-bottom',
  'bottom-left': 'origin-bottom-left',
  'bottom-right': 'origin-bottom-right',
  'top': 'origin-top',
  'top-left': 'origin-top-left',
  'top-right': 'origin-top-right',
}

export const getPositionClass = (
  position: 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-left' | 'bottom-right'
): string => {
  let positionClass = CLASS_POSITON['bottom'];
  match(position)
  .with('top', () => positionClass = CLASS_POSITON['top'])
  .with('top-left', () => positionClass = CLASS_POSITON['top-left'])
  .with('top-right', () => positionClass = CLASS_POSITON['top-right'])
  .with('bottom-right', () => positionClass = CLASS_POSITON['bottom-right'])
  .with('bottom-left', () => positionClass = CLASS_POSITON['bottom-left'])
  .otherwise(() => positionClass = CLASS_POSITON['bottom'])

  return positionClass;
}

export const getOriginClass = (
  position: 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-left' | 'bottom-right'
): string => {
  let originClass = CLASS_ORIGIN['top'];
  match(position)
  .with('top', () => originClass = CLASS_ORIGIN['bottom'])
  .with('top-left', () => originClass = CLASS_ORIGIN['bottom-left'])
  .with('top-right', () => originClass = CLASS_ORIGIN['bottom-right'])
  .with('bottom-right', () => originClass = CLASS_ORIGIN['top-right'])
  .with('bottom-left', () => originClass = CLASS_ORIGIN['top-left'])
  .otherwise(() => originClass = CLASS_ORIGIN['top'])

  return originClass;
}

export const getAutoPositionStyle = ({
  position,
  triggerLeft,
  triggerRight,
  contentWidth,
  triggerWidth,
}:{
  position: 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-left' | 'bottom-right',
  triggerLeft: number,
  triggerRight: number,
  contentWidth: number,
  triggerWidth: number,
}): CSSProperties => {
  switch (position) {
    case 'bottom':
    case 'top':
      const halfContentWidth = Math.round(contentWidth/2);
      const halfTriggerWidth = Math.round(triggerWidth/2);
      const isNearRightOfScreen = triggerRight < triggerLeft;
      const isHasUpdateWithCenter = isNearRightOfScreen ? 
        halfContentWidth > halfTriggerWidth + triggerRight : 
        halfContentWidth > halfTriggerWidth + triggerLeft;
      
      if (!isHasUpdateWithCenter) return {};
      return getPositionStyleWithCenter({ 
        halfTriggerWidth, 
        halfContentWidth,
        triggerRight,
        triggerLeft,
        isNearRightOfScreen
      });

    case 'bottom-left':
    case 'top-left':
      const contentWidthLeft = contentWidth - triggerWidth - triggerRight;
      const isHasUpdateWithLeft = contentWidthLeft > 0;
      if (!isHasUpdateWithLeft) return {};
      return getPositionStyleWithLeft(contentWidthLeft);

    case 'bottom-right':
    case 'top-right':
      const contentWidthRight = contentWidth - triggerWidth - triggerLeft;
      const isHasUpdateWithRight = contentWidthRight > 0;
      if (!isHasUpdateWithRight) return {};
      return getPositionStyleWithRight(contentWidthRight);
  
    default:
      return {};
  }
}

const getPositionStyleWithCenter = ({
  halfContentWidth,
  halfTriggerWidth,
  triggerRight,
  triggerLeft,
  isNearRightOfScreen
}:{
  isNearRightOfScreen: boolean,
  halfContentWidth: number,
  triggerRight: number,
  triggerLeft: number,
  halfTriggerWidth: number,
}): CSSProperties => {
  return {
    left: isNearRightOfScreen ? 
      `calc(50% - ${halfContentWidth - halfTriggerWidth - triggerRight + 20}px)` :
      `calc(50% + ${halfContentWidth - halfTriggerWidth - triggerLeft + 20}px)`
  }
}

const getPositionStyleWithLeft = (contentWidthLeft: number): CSSProperties => {
  return { left: `${-contentWidthLeft - 20}px` }
}

const getPositionStyleWithRight = (contentWidthRight: number): CSSProperties => {
  return { right: `${-contentWidthRight - 20}px` }
}