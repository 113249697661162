'use client';

import { useState, useEffect } from 'react';
import { Images } from "@/components";
import cachedLocalStorage from '@/libs/localStorage';

const BannerEvent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    window.open('https://x.com/aiRight_io/status/1834215454329766055', '_blank')
    onClose();
  }

  const onClose = () => {
    cachedLocalStorage.setWithExpiry('open_banner', "true", 1000 * 60 * 60 * 12);
    setIsOpen(false);
  }

  useEffect(() => {
    const hadOpen = cachedLocalStorage.getWithExpiry('open_banner');
    if (!hadOpen || hadOpen === 'false') {
      setTimeout(() => {
        setIsOpen(true);
      }, 2000);
    }
  }, []);

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full z-[50]">
      <div className="relative w-full h-full p-4 flex items-center justify-center">
        <div onClick={onClose} className="bg-[rgba(0,0,0,0.8)] absolute top-0 left-0 w-full h-full"></div>
        <div className="max-w-[600px] relative rounded-lg overflow-hidden bg-white">
          <Images onClick={handleClick} className="rounded-lg cursor-pointer object-cover" alt="banner" src="https://data.airight.io/home/banner/aiRight-x-Rabible+2.jpg" />
          <div onClick={onClose} className="absolute z-10 text-[20px] text-black bg-white top-4 right-4 flex items-center justify-center cursor-pointer w-6 h-6 rounded-full font-medium leading-[1]">
            <span className="rotate-[133deg] -ml-[1.2px] -mt-[0.5px] block">+</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerEvent;