import { Button, useDisclosure } from '@nextui-org/react';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import React, { useEffect } from 'react';
import Lottie from 'lottie-react';

import { animation_loading, DiscordIconHeader, IconMagicWandWhite } from '@/assets';
import RemixCommon from '@/layouts/remix-common';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TRACK } from '@/configs/constant';
import { useHistoryState } from '@/provider';

interface indexProps {}

const HeaderMenu: React.FC<indexProps> = ({}) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { isRun } = useHistoryState();

  const useModalRemix = useDisclosure({
    onClose() {
      document.body.style.removeProperty('overflow');
    },
  });
  const { onOpen, onClose, isOpen } = useModalRemix;

  const handleOpenModel = () => {
    mixpanel.track(MIXPANEL_TRACK.GENERATE_OPEN);
    document.body.style.overflow = 'hidden';
    onOpen();
  };

  useEffect(() => {
    if (isOpen) {
      onClose();
    }
  }, [pathname, searchParams]);

  return (
    <div className="mr-3 flex sm:hidden">
      <RemixCommon useModalRemix={useModalRemix} />

      <Button
        onClick={handleOpenModel}
        className="rounded-[36px] bg-gradient-to-r from-[#FF844F] to-[#FFA564] py-[6px] pl-[14px] pr-[21px]"
      >
        <div className="flex items-center gap-2">
          {isRun ? (
            <Lottie className="h-[18px]" animationData={animation_loading} loop />
          ) : (
            <IconMagicWandWhite />
          )}
          <span className="text-[16px] font-semibold leading-7 text-white">Create</span>
        </div>
      </Button>
      <Link
        className="relative ml-3 flex h-[44px] w-[44px] items-center justify-center rounded-full bg-gray-50"
        target="_blank"
        href="https://discord.gg/3cct9Dbv"
      >
        <DiscordIconHeader />
        <div className="absolute right-0 top-0 flex h-[14px] w-[14px] items-center justify-center rounded-full bg-orange-500 text-[8px] font-semibold text-white">
          N
        </div>
      </Link>
    </div>
  );
};

export default HeaderMenu;

// import { Button } from '@nextui-org/react';
// import Lottie from 'lottie-react';
// import Link from 'next/link';
// import React from 'react';

// import { fire, IconMagicWandWhite } from '@/assets';
// import { useLayout } from '@/provider/LayoutProvider';

// import { MENU, MENU_KEY } from '../constants';

// const HeaderMenu: React.FC<{
//   isHotStyle?: boolean;
//   scrolled?: boolean;
// }> = ({ isHotStyle, scrolled }) => {
//   const { setIsLoadingBar } = useLayout();

//   const renderMenu = (key: MENU_KEY, text: string) => {
//     switch (key) {
//       case MENU_KEY.AiModel:
//         return (
//           <>
//             <span
//               key={key}
//               // style={{
//               //   background: 'linear-gradient(90deg, #DA613A 0%, #EB476B 42.19%, #7D3DDC 96.87%)',
//               //   WebkitTextFillColor: 'transparent',
//               //   WebkitBackgroundClip: 'text',
//               // }}
//               className="flex"
//             >
//               <Button className="rounded-[36px] bg-gradient-to-r from-[#FF844F] to-[#FFA564] py-[6px] pl-[14px] pr-[21px]">
//                 <div className="flex items-center gap-2">
//                   <IconMagicWandWhite />
//                   <span className="text-[16px] font-semibold leading-7 text-white">Create</span>
//                 </div>
//               </Button>
//             </span>
//           </>
//         );

//       case MENU_KEY.Campaign:
//         return (
//           <p className="relative mr-[20px] text-white">
//             {text}
//             <Lottie
//               className="absolute bottom-[3px] right-[-23px] w-[22px]"
//               autoPlay
//               loop
//               animationData={fire}
//               onDataFailed={() => <></>}
//             />
//           </p>
//         );

//       default:
//         return text;
//     }
//   };

//   return (
//     <div className="flex items-center justify-start xl:hidden">
//       {MENU.map(
//         (menu) =>
//           menu.key === MENU_KEY.AiModel && (
//             <Link
//               key={menu.key}
//               href={menu.link}
//               onClick={() => setIsLoadingBar(true)}
//               aria-selected={isHotStyle && !scrolled}
//               className={`relative mr-3 cursor-pointer text-14 font-semibold aria-selected:text-white lg:mr-4 lg:text-13 `}
//               // className={`relative mr-7 cursor-pointer text-14 font-semibold aria-selected:text-white lg:mr-4 lg:text-13 ${
//               //   menu.key === MENU_KEY.Pricing &&
//               //   'rounded-[100px] border-1 border-solid border-[#DA613A] bg-[#FBEFEB] px-[12px] py-[6px] text-[#DA613A] before:absolute before:-inset-[3px] before:z-[-1] before:hidden before:rounded-[100px] before:bg-[#ffffff1a] hover:border-1 hover:border-solid hover:border-[#DA613A] aria-selected:border-[#FFFFFF] aria-selected:bg-[#FFFFFF1A] aria-selected:before:block aria-selected:hover:border-[#FFFFFF]'
//               // } ${
//               //   (menu.key === MENU_KEY.Attendance || menu.key === MENU_KEY.NftAuthen) && 'hidden'
//               // } `}
//             >
//               {renderMenu(menu.key, 'Create')}
//             </Link>
//           ),
//       )}
//     </div>
//   );
// };

// export default HeaderMenu;
