'use client';

import Link from 'next/link';
import { useParams, usePathname } from 'next/navigation';
import Script from 'next/script';
import React, { useEffect } from 'react';

import { IconDiscussSupport, IconReportSupport, IconSupport } from '@/assets';

import PopoverCustom from '../popover-custom';

const LIST = [
  {
    key: 'report_bug',
    title: 'Report a bug',
    link: 'https://discord.gg/3cct9Dbv',
    icon: <IconReportSupport className="w-[24px]" />
  },
  {
    key: 'discuss',
    title: 'Discuss',
    link: 'https://discord.gg/3cct9Dbv',
    icon: <IconDiscussSupport className="w-[24px]" />
  },
];

const SupportComp: React.FC = () => {
  const pathname = usePathname();
  const { id } = useParams();
  const disable = ['/chat', `/workflow/editor/${id}`].includes(pathname)

  useEffect(() => {
    const win = window as any;

    if (typeof win.Featurebase !== 'function') {
      win.Featurebase = function () {
        (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
      };
    }
    win.Featurebase('initialize_feedback_widget', {
      organization: 'airight2',
      theme: 'light',
      metadata: null, // Attach session-specific metadata to feedback. Refer to the advanced section for the details: https://developers.featurebase.app/install/advanced#metadata
    });
  }, []);

  const trigger = (
    <div
      style={{ background: 'linear-gradient(166deg, #F97C53 35.74%, #FDD263 108%)' }}
      className="flex h-[60px] w-[60px] cursor-pointer items-center justify-center rounded-full shadow-md sm:scale-80"
    >
      <IconSupport className="scale-80" />
    </div>
  );

  const content = (
    <div className="relative w-[280px] rounded-t-[20px] rounded-bl-[20px] bg-white px-6 py-4 shadow-md sm:w-[250px] sm:px-4 sm:py-2">
      <ul>
        {LIST.map((item) => (
          <li key={item.key} className="border-b-1 border-gray-100 py-2 last:border-none">
            {item.key === 'report_bug' ? (
              <button
                data-featurebase-feedback
                className="m-0 flex w-full items-center rounded-xl border-none px-4 py-3 text-[15px] font-medium outline-none transition-all hover:bg-orange-50 sm:py-2 sm:text-[14px]"
              >
                {item.icon}
                <span className="ml-2">{item.title}</span>
              </button>
            ) : (
              <Link
                href={item.link}
                className="flex items-center rounded-xl px-4 py-3 text-[15px] font-medium transition-all hover:bg-orange-50 sm:py-2 sm:text-[14px]"
                target="_blank"
              >
                {item.icon}
                <span className="ml-2">{item.title}</span>
              </Link>
            )}
          </li>
        ))}
      </ul>
      <div className="absolute -bottom-[12px] -right-[2px] h-0 w-0 translate-x-[0.2px] -rotate-[45deg] border-y-[15px] border-l-[15px] border-transparent border-l-white" />
    </div>
  );

  return (
    <>
      {!disable && (
        <>
          <Script src="https://do.featurebase.app/js/sdk.js" id="featurebase-sdk" />
          <div className="fixed bottom-9 right-[20px] z-40 sm:bottom-[50px] sm:right-[14px]">
            <PopoverCustom
              trigger={trigger}
              content={content}
              position="top-right"
              contentClassName="-translate-x-[30px] -translate-y-[15px] sm:-translate-y-[10px]"
            />
          </div>
        </>
      )}
    </>
  );
};

export default SupportComp;
