'use client';

import { useParams, usePathname } from 'next/navigation';
import type { ReactNode } from 'react';
import { useEffect } from 'react';

import Header from '@/layouts/header';
import Sidebar from '@/layouts/sidebar';
import SidebarSmall from '@/layouts/sidebar/SidebarSmall';
import { useLayout } from '@/provider/LayoutProvider';

type IMainProps = {
  meta?: ReactNode;
  children: ReactNode;
  withOnlyHeader?: boolean;
  withEmptyLayout?: boolean;
  isHotStyle?: boolean;
};

const Main = (props: IMainProps) => {
  const pathname = usePathname();
  const { id } = useParams();

  const { setIsLoadingBar, isLoadingBar, isOpenSidebar } = useLayout();

  const isWorkflowEditorScreen = pathname === `/workflow/editor/${id}`;

  useEffect(() => {
    if (isLoadingBar) {
      setIsLoadingBar(false);
    }
    if (isWorkflowEditorScreen) {
      document.body.style.overflow = 'hidden';
    } else document.body.style.removeProperty('overflow');
  }, [pathname]);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        // prevent React's listener from firing
        e.stopImmediatePropagation();
        // prevent the browser's console error message
        e.preventDefault();
      }
    });
  }, []);

  return (
    <div className="w-full text-black">
      {props.meta}

      <div className="w-full">
        {!props.withEmptyLayout && <Header isHotStyle={props?.isHotStyle} />}
        <div className="text-black">
          <div aria-hidden={isWorkflowEditorScreen} className="aria-hidden:hidden xl:hidden">
            <Sidebar isOpen={isOpenSidebar} />
            <SidebarSmall isOpen={isOpenSidebar} />
          </div>
          <div
            aria-checked={isOpenSidebar}
            aria-hidden={isWorkflowEditorScreen}
            className="pl-[88px] pt-[60px] transition-all aria-checked:pl-[210px] aria-hidden:pl-0 aria-hidden:pt-0 xl:!pl-0 xl:pt-[72px]"
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
