import React, { useEffect } from "react";

const usePopover = ({
  contentRef,
  triggerRef,
  onClose,
  onOpenChange,
}:{
  contentRef: React.RefObject<HTMLDivElement>,
  triggerRef: React.RefObject<HTMLDivElement>,
  onClose: () => void,
  onOpenChange?: (isOpen: boolean) => void,
}) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        contentRef.current &&
        !contentRef.current.contains(event.target) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target)
      ) {
        onClose();
        if (onOpenChange) onOpenChange(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [contentRef, triggerRef, onClose]);
}

export default usePopover;