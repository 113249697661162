import { useLayout } from '@/provider/LayoutProvider';
import Link from 'next/link';
import React from 'react';
import useActiveMenu from './useActiveMenu';
import { SIDEBAR_MENU, SIDEBAR_MENU_KEY } from '.';
import { useAuth } from '@/provider/UserProvider';
import { getMarketplaceURL } from './helpers';

const SidebarSmall: React.FC<{
  isOpen: boolean;
}> = ({ isOpen }) => {
  const { setIsLoadingBar } = useLayout();
  const { auth, isLoginClient } = useAuth();
  const { isActive } = useActiveMenu();

  const handleClickMenu = (e: any, key: SIDEBAR_MENU_KEY, link: string, target: string) => {
    if (key === SIDEBAR_MENU_KEY.Marketplace) {
      e.preventDefault();
      window.open(isLoginClient ? getMarketplaceURL(auth.isOwallet || false) : link, '_blank');
    } else if (target !== '_blank') {
      setIsLoadingBar(true);
    }
  };

  return (
    <div
      aria-checked={!isOpen}
      className="fixed transition-all -left-[88px] aria-checked:left-0 px-[10px] py-5 z-[49] bg-white top-[60px] bottom-0 w-[88px] border-r-1 border-gray-200"
    >
      <ul className="max-h-[100%] overflow-x-hidden overflow-y-auto scrollbar-hide">
        {SIDEBAR_MENU.map((menu) => (
          <li key={menu.key} className="my-1">
            <Link
              aria-checked={isActive(menu.link)}
              target={menu.target}
              onClick={(e) => handleClickMenu(e, menu.key, menu.link, menu.target)}
              className="flex flex-col px-[10px] py-3 text-[12px] text-gray-800 font-semibold aria-checked:bg-orange-50 aria-checked:text-orange-500 rounded-[6px]"
              href={menu.link}
            >
              <div className="mb-1 flex justify-center">
                {isActive(menu.link) ? menu.iconActive : menu.icon}
              </div>{' '}
              <span className="line-clamp-1 text-center">{menu.text}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SidebarSmall;
