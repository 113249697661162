'use client';

import useWindowSize from '@/hooks/useWindowSize';
import React, { useEffect, useRef, useState } from 'react';
import { getOriginClass, getPositionClass, getAutoPositionStyle } from './helpers';
import usePopover from './usePopover';

const PopoverCustom: React.FC<{
  trigger?: React.ReactElement,
  content?: React.ReactElement,
  position?: 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-left' | 'bottom-right';
  styles?: React.CSSProperties,
  onOpenChange?: (isOpen: boolean) => void,
  isOpen?: boolean,
  contentClassName?: string,
}> = ({
  trigger = 'Trigger',
  content = 'Content',
  position = 'bottom',
  styles,
  onOpenChange,
  isOpen = false,
  contentClassName = ''
}) => {
  const [visible, setVisible] = useState(isOpen);
  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const { windowSize } = useWindowSize();
  const triggerBounding = triggerRef.current?.getBoundingClientRect();
  const contentWidth = contentRef.current?.offsetWidth ? Math.round(contentRef.current?.offsetWidth) : 0;
  const triggerWidth = triggerBounding?.width || 0;
  const triggerLeft = triggerBounding?.left || 0;
  const triggerRight = windowSize.width - triggerWidth - triggerLeft;

  useEffect(() => {
    setVisible(isOpen);
  }, [isOpen]);

  usePopover({ 
    contentRef, 
    triggerRef, 
    onClose: () => setVisible(false),
    onOpenChange
  });

  const onClickTrigger = () => {
    if (onOpenChange) onOpenChange(!visible);
    setVisible(!visible)
  }

  return (
    <div className="relative">
      <div className="" onClick={onClickTrigger} ref={triggerRef}>
        {trigger}
      </div>
      <div
        aria-checked={visible}
        className={`transition-all invisible aria-checked:visible opacity-0 aria-checked:opacity-100 scale-50 aria-checked:scale-100 absolute ${getPositionClass(position)} ${getOriginClass(position)} ${contentClassName}`}
        style={{ 
          ...styles, 
          ...getAutoPositionStyle({
            position,
            triggerLeft,
            triggerRight,
            contentWidth,
            triggerWidth
          }) 
        }}
        ref={contentRef}
      >
        {content}
      </div>
    </div>
  );
}

export default PopoverCustom;